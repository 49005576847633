import { Module } from "vuex";
import { getThumbnail } from "../components/preview_pdf/pdf2img";
import { Attachment } from "@/API";
import { AttachmentFactory } from "@/model";

export type FileParam = { 
    domainId: string,
    topicId: string,
    messageId?: string,
    commentId?: string,
    fileName: string,
    data?: string,
    thumb?: string,
}

export type FileSignedUrl = {
    key: string,
    signedUrl: string,
} 

type State = { files: FileParam[], pdfThumbStore: { [fileName: string]: File } }
type RootState = { domainId: string, topicId: string, messageId: string };

// fileKeyをparamに分解
const parseKey = ( key: string ): { domainId: string, topicId: string, messageId?: string, commentId?: string, fileName: string } => {
    const pathList = key.split('/');
    const domainId = pathList[0];
    const topicId = pathList[1];
    const backPathList = pathList.slice(2);
    if( backPathList.length === 1 ) {
        // topic
        const fileName = backPathList[0];
        return { domainId, topicId, fileName };
    } else if ( backPathList.length === 2 ) {
        // message
        const messageId = backPathList[0];
        const fileName = backPathList[1];
        return { domainId, topicId, messageId, fileName };
    } else if ( backPathList.length === 3 ) {
        // comment
        const messageId = backPathList[0];
        const commentId = backPathList[1];
        const fileName = backPathList[2];
        return { domainId, topicId, messageId, commentId, fileName };
    } else {
        return { domainId, topicId, fileName: "" };
    }
}

const isPdf = (file: File | Attachment ): boolean =>  {
    if (file instanceof File) {
        return file.type === "application/pdf";
    } else if (AttachmentFactory.isAttachment(file)) {
        return file.mime === "application/pdf";
    }
    return false
}

const fileModule: Module<State, RootState> = {
    namespaced: true,
    state: {
        files: [],
        pdfThumbStore: {},
    },
    getters: {
        getTopicFiles: (state: State) => (domainId:string): FileParam[]|undefined => {
            const topicFiles = state.files.filter(param => param.domainId === domainId && !param.messageId && !param.commentId );
            return topicFiles;
        },
        getMessageFiles: (state: State) => (domainId:string, topicId:string): FileParam[]|undefined => {
            const messageFiles = state.files.filter(param => param.domainId === domainId && param.topicId === topicId &&  param.messageId && !param.commentId );
            return messageFiles;
        },
        getCommentFilesByTopicId: (state: State) => (domainId:string, topicId:string): FileParam[]|undefined => {
            const commentFiles = state.files.filter(param => param.domainId === domainId && param.topicId === topicId &&  param.messageId && param.commentId );
            return commentFiles;
        },
        getCommentFilesByMessageId: (state: State) => (domainId:string, topicId:string, messageId: string): FileParam[]|undefined => {
            const commentFiles = state.files.filter(param => param.domainId === domainId && param.topicId === topicId &&  param.messageId === messageId && param.commentId );
            return commentFiles;
        },
        getPdfThumbStore: (state: State): File[] => { return Object.values(state.pdfThumbStore); },
        getPdfThumb: (state: State) => (file: File ): File | undefined => {
            const fileName = `${file.name + file.size}_tmb.jpg`;
            return state.pdfThumbStore[fileName];
        }
    },
    mutations: {
        setData( state: State, data: { thumb?: FileSignedUrl[], data?: FileSignedUrl[] } ): void {
            const keys: ('thumb'|'data')[] = ['thumb', 'data'];
            for ( const _key of keys ) {
                const dataList = data[_key] || undefined;
                if( !dataList ) continue;
                for( const _data of dataList ) {
                    const param = parseKey( _data.key );
                    const fileParam: FileParam = {
                        ...param,
                        data: _key === 'data' ? _data.signedUrl : undefined,
                        thumb: _key === 'thumb' ? _data.signedUrl : undefined,
                    }
                    const stateData = state.files.find( param => param.domainId === fileParam.domainId && param.topicId === fileParam.topicId && param.messageId === fileParam.messageId && param.commentId === fileParam.commentId && param.fileName === fileParam.fileName );
                    if( !stateData ) {
                        state.files.push( fileParam );
                    } else {
                        if( fileParam.data !== undefined ) { stateData.data = fileParam.data; }
                        if( fileParam.thumb !== undefined ) { stateData.thumb = fileParam.thumb; }
                    }
                }
            }
        },
    },
    actions: {
        async set( { commit }, payload: { thumb?: FileSignedUrl[], data?: FileSignedUrl[] }): Promise<void> {
            const data = payload;
            commit("setData", data);
        },

        async pushPdfThumbStore({ commit, state }, files: File[]): Promise<void> {
            const results = await Promise.all(
                files
                    .filter((file: File): file is File => isPdf(file))
                    .map(async file => {
                        const thumb = await getThumbnail(file);
                        return thumb;
                })
            )
            const resultFiles = results.filter((result: File | undefined): result is File => Boolean(result));
            resultFiles.map( file =>{
                state.pdfThumbStore[file.name] = file;
            })
        },
    }
}

export default fileModule;