import { ServerApiAccess } from "@/server-api-access";

export default class FeatureDao {

    private constructor(){
        // インスタンス作成不可
    }

    /** α／β機能表の取得 */
    public static async list(): Promise<{ [keyword: string ]: string }>{
        ServerApiAccess.logging( "feature.list", {} );
        const api = new ServerApiAccess();
        const results = await api.listFeatures();
        if( results ) {
            return results;
        } else {
            return {};
        }
    }
}
