












































































































































































import { mixins } from "vue-class-component";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import NavigationBar from './NavigationBar.vue';
import { Attachment, AttachmentFactory, Message, Acl } from '../model';
import { v4 as uuidv4 } from "uuid";
import Confirm from "./button/Confirm.vue";
import Cancel from "./button/Cancel.vue";
import { ActionPayload } from "vuex";
import ErrorModal from './ErrorModal.vue'
import TextProcessMixin from './mixin/TextProcessMixin';
import AlertModal from './AlertModal.vue'
import { Route, NavigationGuardNext } from "vue-router";
import EditDiscardMixin from "./mixin/EditDiscardMixin";
import LoadingUpload from './loading/LoadingUpload.vue';
import DragAreaOverlay from './DropAreaOverlay.vue';
import AclManager from "../model/acl-manager";
import { allowCreateMessage, allowFeature, FeatureName } from "../direct-app-config";
import FileSelectionForm from "./FileSelectionForm.vue";
import { AttachmentFileTypes, AttachmentFileTypesDefault, AttachmentFileTypesNone } from "@/suppport-attachment-types";
import { Topic } from "../model";
import S3AccessUtility from "./s3-acceess-utility";

Component.registerHooks([
    'beforeRouteEnter',
    'beforeRouteLeave',
])


// const MESSAGE_TITLE_MAX_LENGTH = 100;  // タイトル文字数100文字まで → titleMaxLength に移動
const MESSAGE_CONTENT_MAX_LENGTH = 2000;

type PrevData = {
    title: string,
    message: string,
    files: (File | Attachment)[],
    acl: Acl,
}

@Component({
    mixins: [
        EditDiscardMixin
    ],
    components: {
        NavigationBar, Confirm, Cancel, ErrorModal, AlertModal, LoadingUpload, DragAreaOverlay, FileSelectionForm
    }
})
export default class MessageEdit extends mixins( Vue, EditDiscardMixin, TextProcessMixin ) {
    name: string = "message-edit";
    target: File | null = null ;
    files: (File | Attachment)[] = [];
    deletedFiles: Attachment[] = []; // 削除するファイル

    titleSource: string = "";
    messageSource: string = "";

    // ACL
    initalAllowWriteAndLikeOtherSource: "ALLOW" | "USERONLY" | "DENY" = "USERONLY";
    allowWriteAndLikeOtherSource: "ALLOW" | "USERONLY" | "DENY" = "USERONLY";
    topicAllowWriteOthersSource: boolean = true; // 話題の「作成者以外書き込みの許可」
    aclSource: Acl = Acl.createDummy();

    radioOptions = [
        { text: "許可する(ゲストを含む)", value: "ALLOW" },
        { text: "許可する(ゲストを除く)", value: "USERONLY" },
        { text: "許可しない", value: "DENY" }
    ]

    replaceParam: { domainId: string, topicId: string } = { domainId: '', topicId: '' }; // router.replace用パラメータ

    prevData: PrevData = { title: "", message: "", files: [], acl: Acl.createDummy() };
    /* アップローディング関係 */
    isUploading: boolean = false;
    uploadFiles: number = 0;
    nowuploads: number = 0;

    submitLock: boolean = false;

    drag: boolean = false;

    pdfThumbStore: File[] = []

    // Vuexの変更監視
    unsubscribeMutation?: () => void;

    @Prop({ default: false }) readonly nav!: boolean;

    /** create: 新規作成 edit: 編集 */
    @Prop({ default: "edit" }) readonly type!: string;

    @Prop({ default: "" }) readonly domainId!: string;          //!< 所属組織ID
    @Prop({ default: "" }) readonly topicId!: string;           //!< 所属話題ID
    @Prop({ default: "" }) readonly messageId!: string;   //!< 投稿ID

    /** タイトル */
    @Prop({ default: "" }) readonly title!: string;
    @Watch( 'title', { immediate: true } ) onTitleChanged(value: string): void { this.titleSource = value; }

    /** 内容 */
    @Prop({ default: "" }) readonly message!: string;
    @Watch( 'message', { immediate: true } ) onMessageChanged(value: string): void { this.messageSource = value; }

    /** ファイル */
    @Prop({ default: ()=>[] }) readonly photos!: Attachment[];
    @Watch( 'photos', { immediate: true } ) onPhotosChanged(value: Attachment[]): void { this.files = value.slice(); }

    @Prop({ default: false }) readonly isModal!: boolean;

    // ファイル添付設定
    @Prop({ default: () => AttachmentFileTypesDefault }) readonly allow_attachment_type!: AttachmentFileTypes;

    // 添付可能か
    get allow_attachment(): boolean { return this.allow_attachment_type != AttachmentFileTypesNone; }

    // ACL
    @Prop({ default: () => Acl.createDummy() }) readonly acl!: Acl;
    @Prop({ default: () => Acl.createDummy() }) readonly topicAcl!: Acl;
    @Watch( 'acl', { immediate: true } ) onAclChanged( value: Acl ): void {
        this.aclSource = value;
        const createCommentAcl = value.base.createComment;
        const parentTopic = this.$store.getters["topics/getOne"](this.domainId, this.topicId) as Topic;
        this.topicAllowWriteOthersSource = parentTopic.acl.createMessageByOthers();
        if( !this.topicAllowWriteOthersSource ) {
            this.allowWriteAndLikeOtherSource = "DENY";
            this.initalAllowWriteAndLikeOtherSource = this.allowWriteAndLikeOtherSource;
            return;
        }
        const allowWriteAndLike = createCommentAcl != "deny";
        const allowGuest = value.guest.create;
        if( allowWriteAndLike && allowGuest ) {
            this.allowWriteAndLikeOtherSource = "ALLOW";
        } else if( allowWriteAndLike && !allowGuest ){
            this.allowWriteAndLikeOtherSource = "USERONLY";
        } else {
            this.allowWriteAndLikeOtherSource = "DENY";
        }
        this.initalAllowWriteAndLikeOtherSource = this.allowWriteAndLikeOtherSource;
    }

    get items(): { label: string, click?: ()=>void, disabled?: boolean }[] {
        return [
            { label: "保存", click: this.onEditComplete, disabled: this.confirmButtonDisabled }
        ];
    }

    get navTitle(): string { return this.type == "create" ? "新規投稿" : "投稿編集" }

    get normalizeMessageId(): string { return this.messageId ? this.messageId : uuidv4(); }
    get fileListViewerParams(): { messageId: string, commentId: string } {
        return {messageId: this.normalizeMessageId, commentId:'' }
    }

    get paperclipClass(): string {
        return this.allow_attachment ? "paperclip" : "paperclip not-allowed";
    }

    get attachmentLabelClass(): Record<string, boolean> {
        return {
          "attachement-label d-flex flex-row align-items-center": true,
          "allow": this.allow_attachment,
          "not-allowed": !this.allow_attachment,
        }
    }

    get titleState(): boolean {
        if( !this.titleSource ) return false;
        const tmp = this.titleSource.trim();
        return this.getLength(tmp) > 0 && this.getLength(tmp) <= this.titleMaxLength();
    }

    get validTitleMessage(): string {
        const tmp = this.titleSource.trim();
        const titleMaxLength = this.titleMaxLength();
        return this.getLength(tmp)? `タイトルは${titleMaxLength}文字以内です(${this.getLength(tmp)}/${titleMaxLength})`:'タイトルは必須項目です';
    }

    get contentState(): boolean {
        if( !this.messageSource ) return false;
        const tmp = this.messageSource.trim().replace(/\r?\n/g, '');
        return this.getLength(tmp) > 0 && this.getLength(tmp) <= MESSAGE_CONTENT_MAX_LENGTH;
    }

    get validContentMessage(): string {
        const tmp = this.messageSource.trim().replace(/\r?\n/g, '');
        return this.getLength(tmp)? `内容は${MESSAGE_CONTENT_MAX_LENGTH}文字以内です(${this.getLength(tmp)}/${MESSAGE_CONTENT_MAX_LENGTH})`:'内容は必須項目です';
    }

    get confirmButtonDisabled(): boolean {
        if( AclManager.isGuest( this.$store, this.domainId ) ) return false;
        return !( this.titleState && this.contentState);
    }

    get editMode(): boolean {
        if( this.titleSource != this.prevData.title ) return true;
        if( this.messageSource != this.prevData.message ) return true;
        if( JSON.stringify(this.files) != JSON.stringify(this.prevData.files) ) return true;
        if( this.initalAllowWriteAndLikeOtherSource != this.allowWriteAndLikeOtherSource ) return true;
        return false;
    }

    get loadingMsg(): string {
        return `添付ファイルを送信中です (${this.nowuploads}/${this.uploadFiles})`;
    }

    // ACL
    /** Topic が createMessageAcl 許可されているかどうかを調べる */
    get createMessageAcl(): boolean {
        if( !this.$store ) return true;

        const topicCreateMssageAcl = this.topicAcl?.base?.createMessage;
        return topicCreateMssageAcl != "deny";
    }
    hasFeature( keyword: FeatureName ): boolean { return allowFeature( keyword, this.$store );}

    titleMaxLength(): number {
        return this.hasFeature( "topic-title-long" ) ? 100 : 20;    // α/βのキーワードはTopicと同じものを使用
    }

    created(): void {
        if(this.photos.length) {
            this.files = this.photos.slice();
        }
        this.prevData = { title: this.title, message: this.message, files: this.photos, acl: this.acl, }
    }

    dropFiles(files: FileList): void {
        const form = this.$refs.fileSelectionForm as FileSelectionForm;
        form.dropFiles(files);
    }

    updateFile(files: (File | Attachment)[]): void {
        this.files = files;

        this.deletedFiles = [];
        // 削除予定ファイルを差分から取得
        this.prevData.files.map( _prevFile => {
            if( AttachmentFactory.isAttachment(_prevFile) ) {
                const exist = this.files.find( file => {
                    return AttachmentFactory.isAttachment(file) && file.url === _prevFile.url;
                });
                if( !exist ) { this.deletedFiles.push(_prevFile); }
            }
        })

        this.changeEditMode(this.editMode);
    }

    onCancel(): void {
        if( this.isModal ) {
            this.$emit('cancelToModal')
        } else if( this.$router && !this.isModal ) {
            this.$router.back();
        }
    }

    // モーダル用
    uploadEvent(): void {
        this.$emit("uploading", { isUploading: this.isUploading, loadingMsg: this.loadingMsg });
    }

    async onEditComplete(): Promise<void> {
        // 送信連打による連続送信を防止
        if( this.submitLock ) return;
        this.submitLock = true;

        // 新規作成前に作成上限を超えている場合はアラート
        if( this.type == "create" && allowCreateMessage( this.domainId, this.topicId, this.$store ) == false ) {
            this.$root.$emit('free-alert');
            this.submitLock = false;
            return;
        }

        const msgId = this.normalizeMessageId;
        const form = this.$refs.fileSelectionForm as FileSelectionForm;

        const s3AccessUtility = S3AccessUtility.getInstance();
        this.isUploading = true;

        if( this.deletedFiles ) {
            // 削除
            await s3AccessUtility.deleteFiles( this.deletedFiles, { domainId: this.domainId, topicId: this.topicId, messageId: msgId })
            this.deletedFiles = [];
        }

        let files: Attachment[] = []
        const generator = async () => {
            let returnValue: boolean = true;
            for await (const result of s3AccessUtility.uploadFiles(
                this.files, { domainId: this.domainId, topicId: this.topicId, messageId: msgId }) 
            ) {
                if( !result ) {
                    returnValue = false;
                    break;
                }

                files = result.attachments;
                this.nowuploads = result.uploaded;
                this.uploadFiles = result.total;
                if( result.done ) {
                    returnValue = true;
                    break;
                }
            }
            return returnValue;
        }
    
        if(this.isModal) this.uploadEvent();
        // アップロード
        const result = await generator();
        if( !result ) {
            this.$root.$emit('show-error-modal', { msg: "添付ファイルの送信に失敗しました。", afterProcess: ()=>{return} })
            return;
        }
        this.isUploading = false;
        if(this.isModal) this.uploadEvent();
        this.nowuploads = 0;
        form.resetFileList();

        if( !files ) return;
        const message = Message.create({
            id: msgId,
            domainId: this.domainId,
            topicId: this.topicId,
            title: this.titleSource,
            message: this.messageSource,
            photos: files,
            acl: Acl.createByMessage( msgId, this.allowWriteAndLikeOtherSource),
        });
        const param: { [key: string]: any } = {
            title: this.titleSource,
            message: this.messageSource,
            photos: files,
            acl: Acl.createByMessage( msgId, this.allowWriteAndLikeOtherSource),
        }
        const obj = this.type == 'edit' ?  { messageId: msgId, param: param } : { message: message };
        const event = this.type == 'edit' ? "on-message-edit" : "on-message-create";
        if( this.$root && this.$root.$emit ) {
            this.$root.$emit( event, obj );
        }
        if( this.$emit ) {
            this.$emit( event, obj );
        }

        this.changeEditMode(false);

        if( this.isModal ) {
            this.$emit('confirmToModal');
        } else if( this.$router && !this.isModal ) {
            this.$router.back(); // 完了後は閉じる
        }
        this.submitLock = false;
    }


    // 削除後のイベント
    afterDeleteProcess( deletedType: string): void {
        switch( deletedType ) {
            case "topic":
                if( this.isModal ) {
                    console.log('domainId', this.replaceParam.domainId);
                    // modal close => $router.replace
                    this.$emit("interruption", { id: 'modal-message', path: `/${this.replaceParam.domainId}`});
                } else {
                    this.$router.replace(`/${this.replaceParam.domainId}`);
                }
                break;
            case "message":
                if( this.isModal ) {
                    // modal close
                    this.$emit("interruption", { id: 'modal-message', path: '' });
                } else {
                    this.$router.replace( `/${this.replaceParam.domainId}/${this.replaceParam.topicId}` );
                }
                break;
            default:
                break;
        }
    }

    // Lifecycle
    beforeDestroy() {
        if( this.unsubscribeMutation ) this.unsubscribeMutation()
    }
    mounted() {
        if( !this.$store ) return;
        this.unsubscribeMutation = this.$store.subscribe( ( action: ActionPayload, state: unknown ) => {
            // 投稿「新規作成中」に「話題」削除 -> 「話題一覧」に移動
            // 投稿「編集中」に「話題」削除 -> 「話題一覧」に移動
            if( action.type == "topics/delete" ) {
                const domainId = this.domainId;
                const topicId = this.topicId;
                if( action.payload.topicId == topicId ) {
                    this.replaceParam = { domainId: domainId, topicId: topicId };
                    this.$root.$emit('show-error-modal', { msg: "話題が削除されたため、話題一覧に移動します", afterProcess: () => this.afterDeleteProcess('topic') })
                }
            }
            // 投稿「編集中」に「投稿」削除 -> 「投稿一覧」に移動
            else if( action.type == "messages/add" && action.payload.messages.length == 1 && action.payload.messages[0].deleted == true ) {
                const domainId = this.domainId;
                const topicId = this.topicId;
                const messageId = this.normalizeMessageId;
                const msg = action.payload.messages[0];
                if( msg && msg.id == messageId ) {
                    this.replaceParam = { domainId: domainId, topicId: topicId };
                    this.$root.$emit('show-error-modal', { msg: "投稿が削除されたため、投稿一覧に移動します", afterProcess: () => this.afterDeleteProcess('message') })
                }
            }
        });
    }
    beforeRouteEnter( to: Route, from: Route, next: NavigationGuardNext ): void {
        // 編集画面でリロードされた場合、投稿ページへリダイレクト
        if( from.name ) { next(); }
        else {
            const domainId = to.params.domainId;
            const topicId = to.params.topicId;
            console.log(`* redirect: /${domainId}/${topicId}`);
            next( `/${domainId}/${topicId}` );
        }
    }
    beforeRouteLeave( to: Route, from: Route, next: NavigationGuardNext ): void {
        this.routeLeaveEvent(next);
    }
}
