





















import { Component, Prop, Vue } from "vue-property-decorator";
import "./file-list-viewer.scss";
import Delete from "./Delete.vue";
import path from 'path';
import S3AccessUtility from './s3-acceess-utility';
import AttachmentImg from './AttachmentImg.vue';
import { Attachment, AttachmentFactory } from "@/model";
import { ALLOW_IMAGE_EXTENSIONS, AttachmentFileTypes, AttachmentFileTypesNone } from "../suppport-attachment-types"
import { OFFICE_TYPE_LIST, judgeOffice, getFileType, getDefaultIcon } from "./file-utility";

// 許可する添付ファイルの拡張子
export const judgeType = (type: string, denyList?: string[] ): boolean => {
    if( denyList
        && denyList.some( denyType => denyType.endsWith("/*") ? type.startsWith( denyType.split("/*")[0] ) : denyType == type )
    ) {
        // 禁止リストに引っかかった
        return false;
    }
    return ( type == "image/jpeg" || type == "image/png" || type == "image/gif" || !!( type.match(/^video\/.*/g) ) || type == "application/pdf" || judgeOffice(type) );
}

// 許可しないMimeのリストを取得
export const getDenyMimeList = ( allow_attachment_type: AttachmentFileTypes, originDenyList?: string[] ): string[] => {
    const defaultDenyList = (originDenyList && originDenyList.length > 0) ? originDenyList : [];

    // 元設定とマージした拡張子を返す
    const result = new Set( ["image/*", "video/*", "application/pdf", ...OFFICE_TYPE_LIST] );
    const keys = Object.keys( allow_attachment_type ) as ( keyof AttachmentFileTypes )[];
    keys.forEach( key => {
        // 添付許可になっているものは拒否リストから削除
        if( allow_attachment_type[ key ] == true ) {
            switch( key ) {
                case "image": result.delete("image/*"); break;
                case "movie": result.delete("video/*"); break;
                case "pdf": result.delete("application/pdf"); break;
                case "office":
                    OFFICE_TYPE_LIST.forEach( officeType => result.delete( officeType ) );
                    break;
            }
        }
    } )
    return Array.from( result ).concat( ...defaultDenyList );
}

// <input>にcaputureが指定されているときのacceptの設定
// MIMEタイプで設定しないとカメラが起動しないためgetAcceptと分けた
export const getCaptureAccept = (allow_attachment_type: AttachmentFileTypes) => {
    return allow_attachment_type !== AttachmentFileTypesNone ? 'image/*' : ''
}

// acceptの設定
export const getAccept = ( allow_attachment_type: AttachmentFileTypes, originAcceptList?: string[] ): string => {
    const defaultAcceptList = (originAcceptList && originAcceptList.length > 0) ? originAcceptList : ["image/*", "video/*", "application/pdf", ...OFFICE_TYPE_LIST];

    // 許可リストの作成
    const acceptList = new Set();
    const keys = Object.keys( allow_attachment_type ) as ( keyof AttachmentFileTypes )[];
    keys.forEach( key => {
        // 添付許可になっているものは拒否リストから削除
        if( allow_attachment_type[ key ] == true ) {
            switch( key ) {
                case "image": acceptList.add("image/*"); break;
                case "movie": acceptList.add("video/*"); break;
                case "pdf": acceptList.add("application/pdf"); break;
                case "office":
                    OFFICE_TYPE_LIST.forEach( officeType => acceptList.add( officeType ) );
                    break;
            }
        }
    } )

    // ↑で作ったリストをフィルタリング
    const results = Array.from( acceptList ).filter( accept => defaultAcceptList.some( defaultAccept => defaultAccept == accept ) );
    let accept = results.join(', ');
    if( accept.match("image/*") ) { accept = accept.replace("image/*", ALLOW_IMAGE_EXTENSIONS.join(', ') ); }
    return accept;
}

@Component({
    components: {
        Delete, AttachmentImg
    }
})
export default class FileListViewer extends Vue {
    name: string = "file-list-viewer";
    imageStore: { name: string, path: string }[] = [];

    @Prop({ default: () => [] }) readonly files!: (File | Attachment)[];

    @Prop({ default: () => ( { messageId: '', commentId: '' } ) })
    readonly param!: { topicId?: string, messageId: string, commentId: string };

    @Prop({ default: () => [] }) readonly pdfThumbStore!: File[]

    async created(): Promise<void> {
        const s3AccessUtility = S3AccessUtility.getInstance();
        await Promise.all(this.files.map( async(file) =>{
            if( AttachmentFactory.isAttachment( file ) ) {
                const name = file.url
                let path: string
                path = ( await s3AccessUtility.getThumbFile( name, this.param ) ) as string;
                this.imageStore.push( { name: name, path: path } );
            }
        }));
    }

    // サムネイルが取得できなかった時にアイコンを表示する
    setDefaultIcon(e: { target: HTMLImageElement }):void {
        e.target.src = "/img/icon/icon_file_pdf.svg";
    }

    // officeのサムネアイコン取得
    getOfficeURL(file: File | Attachment): string {
        return getDefaultIcon(file);
    }

    onLoadThumbnail(e: { target: HTMLImageElement }) {
        if (e.target.getAttribute("src") !== "/img/icon/icon_file_pdf.svg") {
            // サムネイル表示の場合、PDFラベルを表示
            (e.target.nextElementSibling! as HTMLParagraphElement).style.display = "inline"
        } else {
            // PDFラベルを非表示
            (e.target.nextElementSibling! as HTMLParagraphElement).style.display = "none";
        }
    }

    getFileName( file: (File | Attachment) ): string {
        if( AttachmentFactory.isAttachment( file ) ) {
            let fileStr = file.url;
            if(fileStr.slice(-1) == '/' ) {
                fileStr = fileStr.slice(0, -1);
            }
            const splitUrl = fileStr.split(path.sep);
            return S3AccessUtility.decodeName(splitUrl[splitUrl.length-1]);
        } else {
            return file.name;
        }
    }

    // getFileTypeをラッパー *vueでのエラー防止
    getFileTypeWrapper( file: (File | Attachment) ) {
        return getFileType(file);
    }

    getImagePath(file: Attachment | string ): string {
        const name = AttachmentFactory.isAttachment( file ) ? file.url : file;
        const image = this.imageStore.find( obj => obj.name == name );
        return image ? image.path : '';
    }

    getPdfThumbnail(file: File): File | undefined {
        const name = `${file.name + file.size}_tmb.jpg`
        const pdfThumb = this.pdfThumbStore.find(thumb => thumb.name === name)
        return pdfThumb
    }

    createURL(file: File | Attachment | string ): string {
        let path;
        if( AttachmentFactory.isAttachment( file ) ) {
            return this.getImagePath( file );
        } else if( typeof file == "string" ) {
            return this.getImagePath( file );
        } else if (getFileType(file) == 'pdf') {
            const pdfThumb = this.getPdfThumbnail(file)
            if (!pdfThumb) return "/img/icon/icon_file_pdf.svg";
            path = pdfThumb
        } else {
            path = file;
        }
        return URL.createObjectURL( path );
    }

    onDeleteClick(index: number): void {
        this.$emit('deleteFile', index);
    }
}
