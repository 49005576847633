import { API, graphqlOperation } from "aws-amplify";
import { AWSAppSyncRealTimeProvider } from "@aws-amplify/pubsub"
import { Comment as DbComment,
    CreateCommentMutationVariables, CreateCommentMutation,
    ListCommentsByMessageIdQueryVariables, ListCommentsByMessageIdQuery,
    UpdateCommentMutationVariables, UpdateCommentMutation,
    DeleteCommentMutationVariables, DeleteCommentMutation,
    ListCommentsByTopicIdQueryVariables, ListCommentsByTopicIdQuery,
    OnCreateCommentSubscriptionVariables, OnCreateCommentSubscription,
    OnUpdateCommentSubscriptionVariables, OnUpdateCommentSubscription,
    OnDeleteCommentSubscriptionVariables, OnDeleteCommentSubscription,
    GetCommentQueryVariables, GetCommentQuery
} from "@/API"
import { Comment } from "@/model";
import { createComment, deleteComment, updateComment } from "@/graphql/mutations";
import Observable from "zen-observable-ts";
import { ZenObservable } from "zen-observable-ts";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { onCreateComment, onDeleteComment, onUpdateComment } from "@/graphql-custom/subscriptions";
import { ServerApiAccess } from "@/server-api-access";
import { FileSignedUrl } from "./file-store";


type SubscriptionResult<T> = Observable<{
    provider: AWSAppSyncRealTimeProvider;
    value: GraphQLResult<T>;
}>

export type SubscriptionCallback = {
    onCreate: ( domainId: string, topicId: string, messageId: string, commentId: string ) => void,
    onUpdate: ( domainId: string, topicId: string, messageId: string, commentId: string ) => void,
    onDelete: ( domainId: string, topicId: string, messageId: string, commentId: string ) => void,
}

export type CommentReadResult = {
    comments: Comment[],
    nextToken?: string|null|undefined,
}
type CommentReadResultRaw = {
    comments: DbComment[],
    nextToken?: string|null|undefined,
}
export default class CommentDao {

    /** domain毎のSubscription */
    private static subscriptions: {
        [ messageId: string ]: ZenObservable.Subscription[]
    } = {}

    private constructor() {
        // インスタンス作成不可
    }

    /**
     * CommentをDBに登録する
     * @param comment   comment
     */
    public static async create( comment: Comment ): Promise<{ comment: Comment, thumbs: FileSignedUrl[], data: FileSignedUrl[] }|undefined>{
        ServerApiAccess.logging( "comment.create", comment );    // logging
        try {
            const api = new ServerApiAccess();
            const result = await api.createComment(comment);
            if( result ) {
                const comment = Comment.create(result.comment);
                const thumbs = result.thumbs;
                const data = result.data;
                return { comment, thumbs, data }
            } else {
                return undefined;
            }
        } catch( error ) {
            console.error("Comment Add error:%O", error);
            return undefined;
        }
    }

    /**
     * コメントデータを取得する
     * @param messageId     投稿ID
     * @param commentId     コメントID
     * @returns コメントデータ
     */
    public static async read( domainId: string, topicId: string, messageId?: string ): Promise<{ comments: Comment[], thumbs: FileSignedUrl[], data: FileSignedUrl[] }|undefined> {
        ServerApiAccess.logging( "comment.read", { topicId: topicId, messageId: messageId } );
        try {
            const api = new ServerApiAccess();
            const results = await api.listComments(domainId, topicId, messageId);
            if( results && typeof results !== "number" ) {
                const comments = results.comments.map( comment => Comment.create( comment ) );
                return { comments: comments, thumbs: results.thumbs, data: results.data };
            } else {
                return undefined;
            }
        } catch( err ) {
            console.error( err );
            return undefined;
        }
    }

    /**
     * DBのデータ更新
     * @param comment 更新するコメント
     */
    public static async update( comment: Comment ): Promise<{ comment: Comment, thumbs: FileSignedUrl[], data: FileSignedUrl[] }|undefined> {
        ServerApiAccess.logging( "comment.update", comment );    // logging
        try {
            const api = new ServerApiAccess();
            const result = await api.updateComment(comment);
            if( result ) {
                const newComment = Comment.create(result.comment);
                const thumbs = result.thumbs;
                const data = result.data;
                return { comment: newComment, thumbs, data }
            } else {
                console.error("Comment Update error :%O", result );
                return undefined;
            }
        } catch( error ) {
            console.error("Comment Update error: %O", error );
            throw error;
        }
    }

    /**
     * DBから削除実行
     * @param comment 削除するコメント
     */
    public static async delete( comment: Comment ): Promise<boolean> {
        const variables: DeleteCommentMutationVariables = { input: { messageId: comment.messageId, id: comment.id } }

        ServerApiAccess.logging( "comment.delete", variables.input );    // logging

        const op = graphqlOperation( deleteComment, variables );
        try {
            await ( API.graphql( op ) as Promise<{data: DeleteCommentMutation}> );
        } catch( error ) {
            console.error("error:%O", error );
        }

        // 未実装
        return true;
    }

    public static async registerES( comment: DbComment ) {
        const input: UpdateCommentMutationVariables = {
            input: {
                id: comment.id,
                domainId: comment.domainId,
                topicId: comment.topicId,
                messageId: comment.messageId,

                message: comment.message,
                photos: comment.photos,

                owner: comment.owner,
                // updatedAt: new Date().toISOString(),
                createdAt: comment.createdAt,
                deleted: comment.deleted,
                deletedUser: comment.deletedUser,

                // DB非保存
                // like: comment.like,
            }
        }

        const op = graphqlOperation( updateComment, input );
        try {
            const result = ( await API.graphql( op ) ) as GraphQLResult< UpdateCommentMutation >;
            console.log(result);
        } catch( error ) {
            console.error( "Comment Update Error :%O", error );
        }

        const input2: UpdateCommentMutationVariables = {
            input: {
                id: comment.id,
                domainId: comment.domainId,
                topicId: comment.topicId,
                messageId: comment.messageId,

                message: comment.message,
                photos: comment.photos,

                owner: comment.owner,
                updatedAt: comment.updatedAt,
                createdAt: comment.createdAt,
                deleted: comment.deleted,
                deletedUser: comment.deletedUser,

                // DB非保存
                // like: comment.like,
            }
        }

        const op2 = graphqlOperation( updateComment, input2 );
        try {
            const result = ( await API.graphql( op2 ) ) as GraphQLResult< UpdateCommentMutation >;
            console.log(result);
        } catch( error ) {
            console.error( "Comment Update Error :%O", error );
        }
    }

    private static prepareSubscription( messageId: string ) {
        const tmp = this.subscriptions[ messageId ];
        if( tmp == undefined ) this.subscriptions[ messageId ] = [];
    }

    /** サブスクリプションを作成する */
    public static async registerSubscrptions( domainId: string, topicId: string, messageId: string, callback: SubscriptionCallback ): Promise<void> {
        this.prepareSubscription( messageId );

        const subscriptions = this.subscriptions[ messageId ];
        if( 0 < subscriptions.length ) return;  // 登録済み

        // create
        {
            const input: OnCreateCommentSubscriptionVariables = {
                domainId: domainId,
                topicId: topicId,
                messageId: messageId,
            };
            const graphql = await API.graphql(
                graphqlOperation( onCreateComment, input )
            ) as SubscriptionResult<OnCreateCommentSubscription>

            const subscription = graphql.subscribe({
                next: ( { value } ) => {
                    const data = value.data;
                    if( data?.onCreateComment ) {
                        const comment = data.onCreateComment;
                        callback.onCreate( comment.domainId, comment.topicId, comment.messageId, comment.id );
                    }
                },
                error: error => {
                    console.error( JSON.stringify({
                        type: "Comment Register Subscription Error",
                        error: JSON.stringify(error),
                    }) )
                },
            })
            subscriptions.push( subscription );
        }

        // update
        {
            const input: OnUpdateCommentSubscriptionVariables = {
                domainId: domainId,
                topicId: topicId,
                messageId: messageId,
            };
            const graphql = await API.graphql(
                graphqlOperation( onUpdateComment, input )
            ) as SubscriptionResult<OnUpdateCommentSubscription>;
            const subscription = graphql.subscribe({
                next: ( { value } ) => {
                    if( value?.data?.onUpdateComment ) {
                        const comment = value.data.onUpdateComment;
                        callback.onUpdate( comment.domainId, comment.topicId, comment.messageId, comment.id );
                    }
                }
            })
            subscriptions.push( subscription );
        }

        // delete
        {
            const input: OnDeleteCommentSubscriptionVariables = {
                domainId: domainId,
                topicId: topicId,
                messageId: messageId,
            };
            const graphql = await API.graphql(
                graphqlOperation( onDeleteComment, input )
            ) as SubscriptionResult<OnDeleteCommentSubscription>;
            const subscription = graphql.subscribe( {
                next: ({value}) => {
                    if( value?.data?.onDeleteComment ) {
                        const comment = value.data.onDeleteComment;
                        callback.onDelete( comment.domainId, comment.topicId, comment.messageId, comment.id );
                    }
                }
            })
            subscriptions.push( subscription );
        }
    }

    /** サブスクリプションを閉じる */
    public static closeSubscription( messageId: string ): void {
        const tmp = this.subscriptions[ messageId ] || [];
        tmp.forEach( tmp => tmp.unsubscribe() );
        this.subscriptions[ messageId ] = [];
    }
}
