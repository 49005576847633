/**
 * APIサーバへのアクセス用
 */

import axios, { AxiosResponse } from "axios";
import { DirectDomainMembersType } from "./direct-restapi-types";
import { Category, Topic, Message, Comment, User } from "@/model";
import { IconReaction, Topic as DbTopic, Category as DbCategory, Message as DbMessage , Comment as DbComment, Department as DbDepartment, Department, IconReaction as DbReaction } from "./API";
import { ServerUserInfo } from "./aws-config";
import { SolutionLinkResponse } from "./model/solution";
import { EventManager } from "./events/event-manager";
import sentry from "./sentry";
import { FileSignedUrl } from "./store/file-store";

export type Domains = Direct.GetDomains;

// API-GWへの接続設定
const PRODUCT_BASE      = process.env.VUE_APP_PRODUCT_BASE || "/";
const AUTH_SERVER_URL   = process.env.VUE_APP_AUTH_SERVER_URL || "http://localhost:3000/";

const ServerApi = {
    userData: new URL( `${PRODUCT_BASE}userData`, AUTH_SERVER_URL ).toString(),
    flow: new URL( `${PRODUCT_BASE}flow`, AUTH_SERVER_URL ).toString(),
    domainMembers: ( domainId: string, offset?: number, limit?: number ) => {
        const url = new URL( `${PRODUCT_BASE}domain-members`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "domainId", domainId );
        if( offset ) params.append( "offset", String( offset ) );
        if( limit  ) params.append( "limit",  String( limit ) );
        return url.toString();
    },
    listCategories: ( domainId: string ) => {
        const url = new URL( `${PRODUCT_BASE}list-categories`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "domain_id", domainId );
        return url.toString();
    },
    topicListByAcl: (domainId: string) => {
        const url = new URL( `${PRODUCT_BASE}list-topics-by-acl`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "domain_id", domainId );
        return url.toString();
    },
    listMessages: (domainId: string, topicId: string, messageId?: string) => {
        const url = new URL( `${PRODUCT_BASE}list-messages`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "domain_id", domainId );
        params.append( "topic_id", topicId );
        if( messageId ) {
            params.append( "message_id", messageId );
        }
        return url.toString();
    },
    listComments: (domainId: string, topicId: string, messageId?: string) => {
        const url = new URL( `${PRODUCT_BASE}list-comments`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "domain_id", domainId );
        params.append( "topic_id", topicId );
        if( messageId ) {
            params.append( "message_id", messageId );
        }
        return url.toString();
    },
    listReactions: ( domainId: string, topicId?: string ) => {
        const url = new URL( `${PRODUCT_BASE}list-reactions`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "domain_id", domainId );
        if( topicId ) {
            params.append( "topic_id", topicId );
        }
        return url.toString();
    },
    listDepartments: ( domainId: string ) => {
        const url = new URL( `${PRODUCT_BASE}list-departments`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "domain_id", domainId );
        return url.toString();
    },
    listApps: (domainId: string) => {
        const url = new URL( `${PRODUCT_BASE}list-apps`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "domainId", domainId );
        return url.toString();
    },
    listDomains: () => {
        const url = new URL( `${PRODUCT_BASE}list-domains`, AUTH_SERVER_URL );
        return url.toString();
    },
    listUsers: (domainId: string) => {
        const url = new URL( `${PRODUCT_BASE}list-users`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "domain_id", domainId );
        return url.toString();
    },
    getMe: () => {
        const url = new URL( `${PRODUCT_BASE}get-me`, AUTH_SERVER_URL );
        return url.toString();
    },
    getProfileIcon: ( directId: string ) => {
        const url = new URL( `${PRODUCT_BASE}get-profile-icon`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "direct_id", directId );
        return url.toString();
    },
    getFile: ( pathParam: { domainId: string, topicId:string, messageId?: string, commentId?: string }, fileName: string, thumb: boolean, contentType: string ) => {
        const url = new URL( `${PRODUCT_BASE}get-file`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "domain_id", pathParam.domainId );
        params.append( "topic_id", pathParam.topicId );
        if( pathParam.messageId ) {
            params.append( "message_id", pathParam.messageId );
        }
        if( pathParam.commentId ) {
            params.append( "comment_id", pathParam.commentId );
        }
        params.append( "fileName", fileName );
        params.append( "thumb", thumb ? "true" : "false" );
        params.append( "contentType", contentType );
        return url.toString();
    },
    deleteFiles: ( domainId: string, topicId: string, files: {messageId?: string, commentId?: string, fileName: string }[] ) => {
        const url = new URL( `${PRODUCT_BASE}delete-files`, AUTH_SERVER_URL );
        const params = url.searchParams;
        params.append( "domain_id", domainId );
        params.append( "topic_id", topicId );
        const paramStr = JSON.stringify(files);
        params.append( "files", paramStr );
        return url.toString();
    },
    listFeatures: () => {
        const url = new URL( `${PRODUCT_BASE}list-features`, AUTH_SERVER_URL );
        return url.toString();
    },
    getHelpContent: () => {
        const url = new URL( `${PRODUCT_BASE}get-help-content`, AUTH_SERVER_URL );
        return url.toString();
    },
    upsertDepartments: new URL( `${PRODUCT_BASE}upsert-departments`, AUTH_SERVER_URL ).toString(),
    createCategory: new URL( `${PRODUCT_BASE}create-category`, AUTH_SERVER_URL ).toString(),
    updateCategory: new URL( `${PRODUCT_BASE}update-category`, AUTH_SERVER_URL ).toString(),
    createTopic: new URL( `${PRODUCT_BASE}create-topic`, AUTH_SERVER_URL ).toString(),
    updateTopic: new URL( `${PRODUCT_BASE}update-topic`, AUTH_SERVER_URL ).toString(),
    createMessage: new URL( `${PRODUCT_BASE}create-message`, AUTH_SERVER_URL ).toString(),
    updateMessage: new URL( `${PRODUCT_BASE}update-message`, AUTH_SERVER_URL ).toString(),
    createComment: new URL( `${PRODUCT_BASE}create-comment`, AUTH_SERVER_URL ).toString(),
    updateComment: new URL( `${PRODUCT_BASE}update-comment`, AUTH_SERVER_URL ).toString(),
    upsertReaction: new URL( `${PRODUCT_BASE}upsert-reaction`, AUTH_SERVER_URL ).toString(),
    logging: new URL( `${PRODUCT_BASE}logging`, AUTH_SERVER_URL ).toString(),
    notifications: new URL( `${PRODUCT_BASE}notifications`, AUTH_SERVER_URL ).toString(),
    topicMessageCount: new URL( `${PRODUCT_BASE}topic/update-message-count`, AUTH_SERVER_URL ).toString(),
    putFiles: new URL( `${PRODUCT_BASE}put-files`, AUTH_SERVER_URL ).toString(),
    error: new URL( `${PRODUCT_BASE}debug-sentry`, AUTH_SERVER_URL ).toString(),
}

export class ServerApiAccess {

    public constructor() {
        // 空
    }

    // APIのレスポンスに対してイベント処理を行う
    private handleResponse<T>(response: AxiosResponse<T>|undefined, apiLabel: string): T|undefined {
        if( !response ) {
            console.log("response is undefined. or not axios error.");
            return undefined;
        }

        const status = response.status;
        if( status === 200 ) {
            // 問題なし
            return response.data;
        } else if ( status === 401 ) {
            // 再認証: ログアウト処理
            EventManager.logoutEvent();
            return undefined;
        } else if ( status === 403 ) {
            // アクセス権なし
            ServerApiAccess.errorlog(apiLabel, 403, response);
            return undefined;
        } else if ( status === 500 ) {
            // サーバーエラー
            ServerApiAccess.errorlog(apiLabel, 500, response);
            return undefined;
        } else {
            ServerApiAccess.errorlog(apiLabel, undefined, response);
            return undefined;
        }
    }

    /**
     * GET系 API にアクセス
     * @param api API-GW REST API endpoint
     */
    private async getApi<T>( api: string, apiLabel: string): Promise<T|undefined> {
        try {
            const response = await axios.get<T>( api, { withCredentials: true } );
            return this.handleResponse(response, apiLabel);
        } catch (err) {
            if( axios.isAxiosError( err ) ) {
                const errorResponse = err?.response;
                return this.handleResponse(errorResponse, apiLabel);
            } else {
                return this.handleResponse(undefined, apiLabel);
            }
        }
    }

    /**
     * POST系 API にアクセス
     * @param api API-GW REST API endpoint
     */
    private async postApi<T>( api: string, param: any, apiLabel: string): Promise<T|undefined> {
        try {
            const response = await axios.post<T>( api, param, { withCredentials: true } );
            return this.handleResponse(response, apiLabel);
        } catch (err) {
            if( axios.isAxiosError( err ) ) {
                const errorResponse = err?.response;
                return this.handleResponse(errorResponse, apiLabel);
            } else {
                return this.handleResponse(undefined, apiLabel);
            }
        }
    }

    /**
     * PUT系 API にアクセス
     * @param api API-GW REST API endpoint
     * @param param パラメーター
     */
    private async putApi<T>( api: string, param: any, apiLabel: string): Promise<T|undefined> {
        try {
            const response = await axios.put<T>( api, param, { withCredentials: true } );
            return this.handleResponse(response, apiLabel);
        } catch (err) {
            if( axios.isAxiosError( err ) ) {
                const errorResponse = err?.response;
                return this.handleResponse(errorResponse, apiLabel);
            } else {
                return this.handleResponse(undefined, apiLabel);
            }
        }
    }

    /**
     * PUT系 API にアクセス
     * @param api API-GW REST API endpoint
     */
    private async deleteApi<T>( api: string, apiLabel: string ): Promise<T|undefined> {
        try {
            const response = await axios.delete( api, { withCredentials: true } );
            return this.handleResponse(response, apiLabel);
        } catch (err) {
            if( axios.isAxiosError( err ) ) {
                const errorResponse = err?.response;
                return this.handleResponse(errorResponse, apiLabel);
            } else {
                return this.handleResponse(undefined, apiLabel);
            }
        }
    }

    /**
     * /userData にアクセスして、自分のユーザー情報を取得します
     * @returns ユーザー情報。number: 401は未ログインエラー。undefined: その他のエラー
     */
    public async getUserData(): Promise<ServerUserInfo|number|undefined> {
        try {
            const response = await axios.get<{user:ServerUserInfo}>( ServerApi.userData, { withCredentials: true } );

            if( response.status == 200 ) {
                const user = response.data.user as ServerUserInfo;
                return user;
            } else if ( response.status == 401 ) {
                return 401;
            } else {
                return undefined;
            }
        } catch ( err ) {
            console.log("get user data error: ", err);
            if( axios.isAxiosError(err) ) {
                if( err.response?.status == 401 ) {
                    return 401;
                }
            }
            return undefined;
        }
    }

    /**
     * 組織内メンバー一覧を取得する
     * @param domainId 組織ID
     * @param offset オフセット
     * @param limit リミット
     * @returns
     */
    public async getDomainMembers( domainId: string, offset: number = 0, limit: number = 20, ): Promise<DirectDomainMembersType|number|undefined> {
        const result = await this.getApi<DirectDomainMembersType>( ServerApi.domainMembers( domainId, offset, limit ), "getDomainMembers" );
        if( result ) {
            return result;
        } else {
            return undefined;
        }
    }

    /**
     * 話題の投稿数を更新する
     * @param domainId 組織ID
     * @param topicId 話題ID
     * @param updateMessageCount true: 話題の投稿数を更新する
     */
    public async putTopicMessageCount( domainId: string, topicId: string, updateMessageCount: boolean ): Promise<void> {
        const result = await this.putApi<any>( ServerApi.topicMessageCount, { domainId: domainId, topicId: topicId, updateMessageCount: updateMessageCount }, "putTopicMessageCount" );
        return result;
    }

    /**
     * 話題取得
     * @param domainId
     * @returns
     */
    public async getTopics( domainId: string ): Promise<{ topics: DbTopic[], totalCount: number, thumbs: FileSignedUrl[] }|undefined> {
        const result = await this.getApi<{ topics: DbTopic[], totalCount: number, thumbs: FileSignedUrl[] }>( ServerApi.topicListByAcl(domainId), "getTopics" );
        if( result ) {
            return result;
        } else {
            return undefined;
        }
    }

    // API共通処理
    private async _apiAccess<T>( params: string, apiLabel: string ): Promise<T|undefined> {
        const result = await this.getApi<T>( params, apiLabel );
        if( result ) {
            return result;
        } else {
            return undefined;
        }
    }

    /** directのアプリ一覧を取得する */
    public async listApps( domainId: string ): Promise<SolutionLinkResponse|undefined|number> {
        const params = ServerApi.listApps(domainId);
        const result = await this._apiAccess<SolutionLinkResponse>( params, "listApps" );
        return result;
    }

    /** 部署の更新を行う */
    public async upsertDepartments( domainId: string ): Promise<DbDepartment[]|undefined> {
        const result = await this.putApi<DbDepartment[]>( ServerApi.upsertDepartments, { domainId: domainId }, "upsertDepartments" );
        return result;
    }

    /** 部署情報を取得 */
    public async listDepartments( domainId: string ): Promise<Department[]> {
        const result = await this.getApi<Department[]>( ServerApi.listDepartments(domainId), "listDepartments" );
        if( result ) {
            return result;
        } else {
            return [];
        }
    }

    /** directの組織情報を取得 */
    public async listDomains(): Promise<Domains|undefined> {
        const params = ServerApi.listDomains();
        const result = await this._apiAccess<Domains>(params, "listDomains");
        if( !result || result.length == 0 ) {
            EventManager.logoutEvent();
            return undefined;
        }
        return result;
    }

    /**
     * カテゴリー作成
     * @param category
     * @returns
     */
    public async createCategory( category: Category ): Promise<DbCategory|undefined> {
        const result = await this.putApi<DbCategory>( ServerApi.createCategory, category, "createCategory" );
        return result;
    }

    /**
     * カテゴリー一覧取得
     * @param domainId
     * @returns
     */
    public async listCategories( domainId: string ): Promise<DbCategory[]|undefined> {
        const result = await this.getApi<DbCategory[]>( ServerApi.listCategories(domainId), "listCategories" );
        if( result ) {
            return result;
        } else {
            return undefined;
        }
    }

    /**
     * カテゴリー編集
     * @param category
     * @returns
     */
    public async updateCategory( category: Partial<Category> ): Promise<DbCategory|undefined> {
        const result = await this.putApi<DbCategory>( ServerApi.updateCategory, category, "updateCategory" );
        return result;
    }

    /**
     * 話題作成
     * @param topic
     * @returns
     */
    public async createTopic( topic: Topic ): Promise<DbTopic|undefined> {
        const result = await this.putApi<DbTopic>( ServerApi.createTopic, topic, "createTopic" );
        // 無料版の制限エラー
        if( typeof result === "string" && (result as string).match(/exceeded limit/) ) {
            EventManager.freeAlertEvent();
            return undefined;
        } else if( result ) {
            return result;
        } else {
            ServerApiAccess.errorlog( "createTopic", result )
            return undefined;
        }
    }

    /**
     * 話題更新
     * @param topic
     * @returns
     */
    public async updateTopic( topic: Partial<Topic> ): Promise<DbTopic|undefined> {
        const result = await this.putApi<DbTopic>( ServerApi.updateTopic, topic, "updateTopic" );
        return result;
    }

    /**
     * 投稿作成
     * @param message
     * @returns
     */
    public async createMessage( message: Message ): Promise<{ message: DbMessage, thumbs: FileSignedUrl[], data: FileSignedUrl[] }|undefined> {
        const result = await this.putApi<{ message: DbMessage, thumbs: FileSignedUrl[], data: FileSignedUrl[] }>( ServerApi.createMessage, message, "createMessage" );
        // 無料版の制限エラー
        if( typeof result === "string" && (result as string).match(/exceeded limit/) ) {
            EventManager.freeAlertEvent();
            return undefined;
        } else if( result ) {
            return result;
        } else {
            ServerApiAccess.errorlog( "createMessage", result )
            return undefined;
        }
    }

    /**
     * 投稿編集
     * @param message
     * @returns
     */
    public async updateMessage( message: Partial<Message> ): Promise<{ message: DbMessage, thumbs: FileSignedUrl[], data: FileSignedUrl[] }|undefined> {
        const result = await this.putApi<{ message: DbMessage, thumbs: FileSignedUrl[], data: FileSignedUrl[] }>( ServerApi.updateMessage, message, "updateMessage" );
        return result;
    }


    /**
     * 投稿一覧取得
     * @param domainId
     * @param topicId
     * @param messageId
     * @returns
     */
    public async listMessages( domainId: string, topicId: string, messageId?: string ): Promise<{ messages: DbMessage[], thumbs: FileSignedUrl[], data: FileSignedUrl[] }|undefined> {
        const result = await this.getApi<{ messages: DbMessage[], thumbs: FileSignedUrl[], data: FileSignedUrl[] }>( ServerApi.listMessages(domainId, topicId, messageId), "listMessages" );
        if( result ) {
            return result;
        } else {
            return undefined;
        }
    }

    /**
     * コメント作成
     * @param comment
     * @returns
     */
    public async createComment( comment: Comment ): Promise<{ comment: DbComment, thumbs: FileSignedUrl[], data: FileSignedUrl[] }|undefined> {
        const result = await this.putApi<{ comment: DbComment, thumbs: FileSignedUrl[], data: FileSignedUrl[] }>( ServerApi.createComment, comment, "createComment" );
        return result;
    }

    /**
     * コメント更新
     * @param comment
     * @returns
     */
    public async updateComment( comment: Partial<Comment> ): Promise<{ comment: DbComment, thumbs: FileSignedUrl[], data: FileSignedUrl[] }|undefined> {
        const result = await this.putApi<{ comment: DbComment, thumbs: FileSignedUrl[], data: FileSignedUrl[] }>( ServerApi.updateComment, comment, "updateComment" );
        return result;
    }

    /**
     * コメント一覧取得
     * @param domainId
     * @param topicId
     * @param messageId
     * @returns
     */
    public async listComments( domainId: string, topicId: string, messageId?: string ): Promise<{ comments: DbComment[], thumbs: FileSignedUrl[], data: FileSignedUrl[] }|undefined> {
        const result = await this.getApi<{ comments: DbComment[], thumbs: FileSignedUrl[], data: FileSignedUrl[] }>( ServerApi.listComments(domainId, topicId, messageId), "listComments" );
        if( result ) {
            return result;
        } else {
            return undefined;
        }
    }

    /**
     * リアクション作成/更新
     * @param reaction
     * @param value リアクションのチェック値
     * @returns
     */
    public async upsertReaction( reaction: IconReaction, value: boolean ): Promise<DbReaction|undefined> {
        const result = await this.putApi<DbReaction>( ServerApi.upsertReaction, { ...reaction, value: value }, "upsertReaction" );
        return result;
    }

    /**
     * リアクション一覧取得
     * @param domainId
     * @param topicId
     * @returns
     */
    public async listReactions( domainId: string, topicId?: string ): Promise<{ topicReactions: DbReaction[], messageReactions: DbReaction[], commentReactions: DbReaction[] }|undefined> {
        const result = await this.getApi<{ topicReactions: DbReaction[], messageReactions: DbReaction[], commentReactions: DbReaction[] }>( ServerApi.listReactions(domainId, topicId), "listReactions" );
        if( result ) {
            return result;
        } else {
            return undefined;
        }
    }

    /**
     * ユーザ一覧取得
     * @param domainId
     * @returns
     */
    public async listUsers( domainId: string ): Promise<User[]|undefined> {
        const result = await this.getApi<User[]>( ServerApi.listUsers(domainId), "listUsers" );
        if( result ) {
            const users = result.map( data => {
                const user = User.createNotFoundUser();
                user.override(data);
                return user;
            })
            return users;
        } else {
            return undefined;
        }
    }

    /**
     * 自身の情報を取得
     * @returns
     */
    public async getMe(): Promise<User|undefined> {    
        const result = await this.getApi<User>( ServerApi.getMe(), "getMe" );
        if( result && result.id && result.directId && result.name ) {
            const user = User.createNotFoundUser();
            user.override(result);
            return user;
        } else {
            return undefined;
        }
    }

    /**
     * プロフィール画像取得
     * @param directId
     * @returns
     */
    public async getProfileIcon( directId: string ): Promise<string|undefined> {
        try {
            const response = await axios.get<{profileIcon: string}>( ServerApi.getProfileIcon(directId), { withCredentials: true } );
            if( response.status == 200 ) {
                const icon = response.data.profileIcon;
                return icon;
            } else {
                return undefined;
            }
        } catch ( err ) {
            return undefined;
        }
    }

    /**
     * ファイル取得
     * @param pathParam 
     * @param fileName 
     * @param thumb 
     * @returns 
     */
    public async getFile(pathParam: { domainId: string, topicId:string, messageId?: string, commentId?: string }, fileName: string, thumb: boolean, contentType: string ): Promise<FileSignedUrl|undefined> {
        const result = await this.getApi<FileSignedUrl>( ServerApi.getFile(pathParam, fileName, thumb, contentType), "getFile" );
        if( result ) {
            return result;
        } else {
            return undefined;
        }
    }

    /**
     * ファイル送信
     * @param domainId 
     * @param topicId 
     * @param fileParams 
     * @returns 
     */
    public async putFiles(domainId: string, topicId: string, fileParams: { messageId?: string, commentId?: string, fileName: string, contentType: string, size: number }[]): Promise<{ data: FileSignedUrl[], thumb: FileSignedUrl[] }|undefined> {
        const result = await this.putApi<{ data: FileSignedUrl[], thumb: FileSignedUrl[] }>( ServerApi.putFiles, { domainId, topicId, files: fileParams }, "putFiles" );
        return result;
    }

    /**
     * ファイル削除
     * @param domainId 
     * @param topicId 
     * @param fileParams 
     * @returns 
     */
    public async deleteFiles(domainId: string, topicId: string, fileParams: { messageId?: string, commentId?: string, fileName: string }[]): Promise<{ thumb: FileSignedUrl[], data: FileSignedUrl[] }|undefined> {
        const result = await this.deleteApi<{ thumb: FileSignedUrl[], data: FileSignedUrl[] }>( ServerApi.deleteFiles( domainId, topicId, fileParams), "deleteFiles" );
        return result;
    }

    /**
     * allowFeatureの一覧取得
     * @returns 
     */
    public async listFeatures(): Promise<{ [keyword: string]: string }|undefined> {
        const result = await this.getApi<{ [keyword: string]: string }>( ServerApi.listFeatures(), "listFeatures" );
        if( result ) {
            return result;
        } else {
            return undefined;
        }
    }

    /**
     * ヘルプコンテンツの取得
     * @returns
     */
    public async getHelpContent(): Promise<{ title: string, content: string }|undefined> {
        const result = await this.getApi<{ title: string, content: string }>( ServerApi.getHelpContent(), "getHelpContent" );
        if( result ) {
            return result;
        } else {
            return undefined;
        }
    }

    /** ロガーに記録する */
    public static logging( command: string, params: any ): void {    
        const api = new ServerApiAccess();
        api.postApi<any>( ServerApi.logging, {
            command,
            params,
        }, "logging" )
    }

    private static errorlog( label: string, status: unknown, err?: any ): void {
        let msg = `[${label}] error code:${status}`;
        if( err ) msg += `data:${JSON.stringify( err )}`
        console.error( msg )
        sentry.sendSentryError( new Error( msg ) )
    }

    public static debugSentry(): void {
        const api = new ServerApiAccess();
        api.getApi( ServerApi.error, "debugSentry" );
    }

}
