/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:064a7753-ffd6-4da3-802b-3930469bb942",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_G4oQmo7Rs",
    "aws_user_pools_web_client_id": "68thsvhr68upjn7p1nl625ovlr",
    "oauth": {
        "domain": "staging-dxflow.auth.ap-northeast-1.amazoncognito.com",
        "scope": [
            "aws.cognito.signin.user.admin",
            "email",
            "openid",
            "phone",
            "profile"
        ],
        "redirectSignIn": "http://localhost:8080,https://forum.staging.dx-flow.direct-ccs.com,https://forum.staging.dx-flow.feel-on.com",
        "redirectSignOut": "http://localhost:8080,https://forum.staging.dx-flow.direct-ccs.com,https://forum.staging.dx-flow.feel-on.com",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [],
    "aws_appsync_graphqlEndpoint": "https://h2r4zgjzkfaofijgsffa6mzbrm.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_dangerously_connect_to_http_endpoint_for_testing": true,
    "aws_cloud_logic_custom": [
        {
            "name": "OpenchannelRestApi",
            "endpoint": "https://4kn3jnhyai.execute-api.ap-northeast-1.amazonaws.com/mori",
            "region": "ap-northeast-1"
        }
    ],
    "aws_user_files_s3_bucket": "openchannel.image-storage",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
