import { AttachmentFactory } from "@/model";
import path from 'path';
import store from "../store/index";
import { v4 as uuidv4 } from "uuid";
import { FileParam, FileSignedUrl } from '@/store/file-store';
import { EventManager } from '@/events/event-manager';
import axios from "axios";
import { ServerApiAccess } from '@/server-api-access';
import { Attachment } from '@/API';

/**
 * S3へのデータアクセスクラス
 * ファイルはS3の /domainId/topicId/messageId[/commentId]/ に保存される
 */
export default class S3AccessUtility {
    private static instance: S3AccessUtility;

    /* 現在設定されている組織ID。組織変更じに再設定されます */
    private domainId: string = '';

    /* 現在設定されている話題ID。話題変更時に再設定されます */
    private topicId: string = '';

    public static getInstance(): S3AccessUtility {
        if( !S3AccessUtility.instance ) {
            this.instance = new S3AccessUtility();
            this.instance.init();
        }
        return this.instance;
    }

    public static encodeName(fileName: string): string {
        return fileName.replace(/\./, `#${uuidv4()}.`);
    }

    public static decodeName(encode: string): string {
        const uuiodReg = /#([0-9a-fA-F]{8}[-][0-9a-fA-F]{4}[-][0-9a-fA-F]{4}[-][0-9a-fA-F]{4}[-][0-9a-fA-F]{12})/;
        const match = encode.match(uuiodReg);
        if( !match || match.length < 1 ) {
            // マッチしない場合はそのままのファイル名を返す
            return encode;
        }
        const uuid = match[1];
        return encode.replace(`#${uuid}`, "");
    }

    public init(): void {
        this.domainId = '';
        this.topicId = '';
    }

    private isPdf(file: File | Attachment ): boolean {
        if (file instanceof File) {
            return file.type === "application/pdf";
        } else if (AttachmentFactory.isAttachment(file)) {
            return file.mime === "application/pdf";
        }
        return false
    }

    private isAndroidDevice(): boolean {
        return store.getters["isAndroid"];
    }

    public setDomainId(domainId: string): void {
        this.domainId = domainId;
    }

    public setTopicId(topicId: string): void {
        this.topicId = topicId;
    }

    /**
     * ファイルのアップロード (generator)
     * 1つファイルがアップロード完了する度に、
     * 完了したファイル数、アップロードするファイル数、全ての添付ファイル、処理の終了フラグ
     * を返す
     * @param files 
     * @param param 
     * @returns 
     */
    public async *uploadFiles(files: (File|Attachment)[], param: { domainId: string, topicId: string, messageId?: string, commentId?: string } ) {
        try {
            const fileParams: { messageId?: string, commentId?: string, fileName: string, contentType: string, size: number }[] = [];
            files.map( file => {
                if( !AttachmentFactory.isAttachment( file ) ) {
                    fileParams.push({
                        messageId: param.messageId,
                        commentId: param.commentId,
                        fileName: file.name,
                        contentType: file.type,
                        size: file.size
                    });
                }
            });

            const access = new ServerApiAccess();
            const results = await access.putFiles(param.domainId, param.topicId, fileParams);
            if( !results ) return { uploaded: 0, total: 0, done: true };
            let uploaded: number = 0;
            const fileData = results.data;
            const total: number = fileData.length;
            const attachments: Attachment[] = [];
            yield { uploaded, total, attachments, done: false }

            for( const file of files ) {
                if( !AttachmentFactory.isAttachment( file ) ) {
                    const result = fileData.find( _res => !!_res.key.match(file.name) );
                    if( result ) {
                        await axios.put(
                            result.signedUrl,
                            file,
                            { 
                                headers: { 'Content-Type': file.type },
                            }
                        )

                        // pdf用のサムネ作成
                        if( this.isPdf(file) && results.thumb.length ) {
                            const thumb = results.thumb.find( _res => !!_res.key.match(file.name) );
                            if( thumb ) {
                                const pdfThumb = store.getters["files/getPdfThumb"](file) as File;
                                if( pdfThumb ) {
                                    await axios.put(
                                        thumb.signedUrl,
                                        pdfThumb,
                                        {
                                            headers: { 'Content-Type': pdfThumb.type },
                                        }
                                    )
                                }
                            }
                        }
                        uploaded++;
                        const attachment = AttachmentFactory.createFromFile(file);
                        attachments.push(attachment);
                        yield { uploaded, total, attachments, done: false };
                    } else {
                        yield { uploaded, total, attachments, done: false };
                    }
                } else {
                    attachments.push(file);
                    yield { uploaded, total, attachments, done: false };
                }
            }
            return { uploaded, total, attachments, done: true };
        } catch (err) {
            console.log("uploadFiles failed. ", err);
            return undefined;
        }
    }

    // 有効期限の確認とURLの再取得
    private async confirmExpire( signedUrl: string, param: { topicId?: string, messageId: string, commentId: string }, fileName: string, contentType: string ) {
        try {
            if( !signedUrl ) {
                const result = await this.retrySignedUrl(param, fileName, contentType);
                if( result ) return result.signedUrl;
                else { return ""; }
            }
            await axios.get(signedUrl);
            return signedUrl;
        } catch ( error ) {
            if( axios.isAxiosError( error ) ) {
                if( error.response?.status == 403 ) {
                    const result = await this.retrySignedUrl(param, fileName, contentType);         
                    if( result ) return result.signedUrl;
                }
            }
            return "";
        }
    }

    // ファイルの署名付きURLの再取得
    private async retrySignedUrl( param: { topicId?: string, messageId: string, commentId: string }, fileName: string, contentType: string ): Promise<FileSignedUrl|undefined> {
        try {
            const domainId = store.state.domainId;
            const topicId = store.state.topicId;
            const access = new ServerApiAccess();
            const pathParam = { domainId: domainId, topicId: topicId, ...param }
            const result = await access.getFile(pathParam, fileName, false, contentType);
            if( result ) {
                store.dispatch("files/set", { data: [result] });
            } else {
                const key = `${domainId}/${topicId}/${param.messageId?`${param.messageId}/`:''}${param.commentId?`${param.commentId}/`:''}${fileName}`;
                store.dispatch("files/set", { data: [{ key: key, signedUrl: "" }] });
            }
            return result;
        } catch (err) {
            return undefined;
        }
    }

    /**
     * GET: ファイルをS3から取得
     * @param fileName
     * @param param
     */
    public async getFile( fileName: string, param: { topicId?: string, messageId: string, commentId: string }, contentType: string ): Promise<unknown|string> {
        fileName = path.basename( fileName );   // 余計なパラメータ類を削除
        const topicId = param.topicId || this.topicId;
        if(param.messageId && !param.commentId) {
            // 投稿
            const messageFiles = store.getters["files/getMessageFiles"](this.domainId, topicId) as FileParam[];
            if( !messageFiles ) return "";
            const messageFile = messageFiles.find( (file: FileParam) => { return file.fileName == fileName && file.messageId == param.messageId; });
            if( messageFile && messageFile.data ) {
                await this.confirmExpire(messageFile.data, param, fileName, contentType);
                return messageFile.data;
            } else {
                return "";
            }
        } else if(param.messageId && param.commentId) {
            // コメント
            const commentFiles = store.getters["files/getCommentFilesByTopicId"](this.domainId, topicId) as FileParam[];
            if( !commentFiles ) return "";
            const commentFile = commentFiles.find( (file: FileParam) => { return file.fileName == fileName && file.messageId == param.messageId && file.commentId == param.commentId; });
            if( commentFile && commentFile.data ) {
                await this.confirmExpire(commentFile.data, param, fileName, contentType);
                return commentFile.data;
            } else {
                return "";
            }
        } else {
            console.log(`*Invalid Path. reads/${this.domainId}/${topicId}/${param.messageId}/${param.commentId}/ ファイル名: ${fileName}` );
            return "";
        }
    }

    /**
     * GET: ファイルをS3から取得
     * @param fileName
     * @param param
     */
     public async getThumbFile( fileName: string, param: { topicId?: string, messageId: string, commentId: string } ): Promise<unknown|string> {    
        fileName = path.basename( fileName );   // 余計なパラメータ類を削除
        const topicId = param.topicId || this.topicId;
        if(param.messageId && !param.commentId) {
            // 投稿
            const messageFiles = store.getters["files/getMessageFiles"](this.domainId, topicId) as FileParam[];
            if( !messageFiles ) return "";
            const messageFile = messageFiles.find( (file: FileParam) => { return file.fileName == fileName && file.messageId == param.messageId; });
            if( messageFile && messageFile.thumb ) {
                return messageFile.thumb;
            } else {
                return "";
            }
        } else if(param.messageId && param.commentId) {
            // コメント
            const commentFiles = store.getters["files/getCommentFilesByTopicId"](this.domainId, topicId) as FileParam[];
            if( !commentFiles ) return "";
            const commentFile = commentFiles.find( (file: FileParam) => { return file.fileName == fileName && file.messageId == param.messageId && file.commentId == param.commentId; });
            if( commentFile && commentFile.thumb ) {
                return commentFile.thumb;
            } else {
                return "";
            }
        } else if( topicId != "" && param.messageId == "" && param.commentId == "" ) {
            // 話題
            const topicsFiles = store.getters["files/getTopicFiles"](this.domainId) as FileParam[];
            if( !topicsFiles ) return "";
            const topicFile = topicsFiles.find( (file: FileParam) => { return file.fileName == fileName && file.topicId && param.topicId; });
            if( topicFile && topicFile.thumb ) {
                return topicFile.thumb;
            } else {
                return "";
            }
        } else {
            const rootPath = "thumb";
            console.log(`*Invalid Path. ${rootPath}/${this.domainId}/${topicId}/${param.messageId}/${param.commentId}/ ファイル名: ${fileName}` );
            return fileName;
        }
    }

    /**
     * ファイル削除
     * @param files 
     * @param param 
     * @returns 
     */
    public async deleteFiles( files: Attachment[], param: { domainId: string, topicId: string, messageId?: string, commentId?: string }): Promise<void> {
        try {
            const fileParams: { messageId?: string, commentId?: string, fileName: string }[] = [];
            files.map( file => {
                if( AttachmentFactory.isAttachment( file ) ) {
                    fileParams.push({
                        messageId: param.messageId,
                        commentId: param.commentId,
                        fileName: file.url,
                    });
                }
            });

            const access = new ServerApiAccess();
            const results = await access.deleteFiles(param.domainId, param.topicId, fileParams);
            if( !results ) { return; }
            const data = results.data;
            await Promise.all(data.map( async result => {
                await axios.delete( result.signedUrl );
            }))

            // サムネの削除
            const thumb = results.thumb;
            await Promise.all(thumb.map( async result => {
                await axios.delete( result.signedUrl );
            }))
        } catch ( err ) {
            console.log("uploadFiles failed.", err);
        }
    }

    /**
     * GET+DOWNLOAD: S3から取得したファイルのダウンロードを実行
     * @param fileName
     * @param param
     * @param downloadName // ダウンロード時のファイル名
     */
    public async downloadFile( fileName: string, param: { topicId?: string, messageId: string, commentId: string }, contentType: string, downloadName?: string ): Promise<void> {
        try {
            fileName = path.basename( fileName );   // 余計なパラメータ類を削除
            const domainId = store.state.domainId;
            const topicId = store.state.topicId;
            const access = new ServerApiAccess();
            const pathParam = { domainId: domainId, topicId: topicId, ...param }
            const result = await access.getFile(pathParam, fileName, false, contentType);
            if( !result ) { return; }
            const signedUrl = result.signedUrl;
            const response = await axios.get(signedUrl, { responseType: "blob" });

            if( this.isAndroidDevice() ) {
                // Androidのみ download handler でメモリ解放
                const eventListener = (event:any) => {
                    if (event.url === url) {
                        URL.revokeObjectURL(url);
                        window.removeEventListener('directjsapidownloadfinished', eventListener);
                    }
                }
                window.addEventListener('directjsapidownloadfinished', eventListener);
            }

            const blob = new Blob([response.data], { type: contentType });
            const url = URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = downloadName ?? fileName;
            a.click();
            
            if( !this.isAndroidDevice() ) {
                // Android以外は即メモリ解放
                URL.revokeObjectURL(url);
            }
        } catch ( err ) {
            console.log("downloadFile failed.", err);
        }
    }
}
