



















































































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import path from 'path';

import FileListViewer, { judgeType, getDenyMimeList, getAccept, getCaptureAccept } from "./FileListViewer.vue";
import { v4 as uuidv4 } from "uuid";

import S3AccessUtility from './s3-acceess-utility';
import { Attachment, AttachmentFactory } from "../model/attachment";

import { ALLOW_IMAGE_EXTENSIONS, AttachmentFileTypes, AttachmentFileTypesDefault, AttachmentFileTypesNone } from "../suppport-attachment-types";
import { OFFICE_TYPE_LIST } from "./file-utility";

type ContenType = "TOPIC" | "MESSAGE" | "COMMENT";

@Component({
    components: { FileListViewer, }
})
export default class FileSelectionForm extends Vue {
    name: string = 'file-selection-form';

    target: File | null = null ;
    files: (File | Attachment)[] = [];
    deletedFiles: Attachment[] = []; // 削除するファイル

    denyMimeType = ["video/*", "application/pdf", ...OFFICE_TYPE_LIST];  //!< 添付に禁止するファイルタイプ (話題用)

    dropLock: boolean = false; // D&Dによる重複送信を防ぐ

    @Prop({ default: "TOPIC", required: true }) readonly contentType!: ContenType;

    @Prop({ default: undefined }) readonly topicId?: string;    //!< 話題の添付ファイルの場合は指定。投稿／コメントの場合は指定無し
    @Prop({ default: undefined }) readonly messageId?: string;  //!< 投稿の添付ファイルの場合は指定。話題の場合は指定しない
    @Prop({ default: undefined }) readonly commentId?: string;  //!< コメントの添付ファイルの場合は指定。話題／投稿の場合は指定しない

    // フォームのラベル指定
    @Prop({ default: "" }) readonly label!: string;

    // 添付ファイル
    @Prop({ default: () => [] }) readonly photos!: Attachment[];

    // true: 複数選択 false: 単一選択
    @Prop({ default: true }) readonly multiple!: boolean;

    // ファイル添付設定
    @Prop({ default: () => AttachmentFileTypesDefault }) readonly allow_attachment_type!: AttachmentFileTypes;

    // ファイル数上限
    @Prop({ default: undefined }) readonly fileNumberLimit?: number;

    get pdfThumbStore(): File[] {
        return this.$store.getters["files/getPdfThumbStore"];
    }

    get fileInputId(): string {
        const topicId = this.topicId || "";
        const messageId = this.messageId || "";
        const commentId = this.commentId || "";
        return `fileInput${topicId}${messageId}${commentId}`;
    }

    get cameraInputId(): string {
        const topicId = this.topicId || "";
        const messageId = this.messageId || "";
        const commentId = this.commentId || "";
        return `cameraInput${topicId}${messageId}${commentId}`;
    }

    get fileFormLabel(): string {
        return this.isMobile ? "ファイルを添付" : "ファイルをドロップまたは選択して添付";
    }

    // ファイルサイズ上限
    get fileSizeLimit(): number {
        if( !this.$store ) { return 10000000; }
        return this.$store.getters["domains/getMaxFileSize"] as number;
    }

    // ファイルサイズ上限単位表記
    get fileSizeLimitUnit(): string {
        if( !this.$store ) { return "10MB"; }
        return this.$store.getters["domains/getFileSizeUnit"] as string;
    }

    get isMobile(): boolean {
        if( !this.$store ) { return false; }
        return this.$store.getters["isMobile"];
    }

    // ファイルリストのパラメータ
    get fileListViewerParams(): { topicId?: string, messageId: string, commentId: string } {
        if( this.topicId ) {
            return { topicId: this.topicId, messageId: "" , commentId: "" }
        } else if( this.messageId && !this.commentId ) {
            return { messageId: this.messageId, commentId: "" }
        } else if( this.messageId && this.commentId ) {
            return { messageId: this.messageId, commentId: this.commentId }
        } else {
            return { messageId: uuidv4(), commentId: uuidv4() }
        }
    }

    // 添付可能か
    get allow_attachment(): boolean { return this.allow_attachment_type != AttachmentFileTypesNone; }

    get attachmentLinkClass(): string {
        return !this.disabled ? "file-selection-form attachment-link" : "file-selection-form attachment-link not-allowed";
    }

    get attachementLabelStyle(): string {
        return !this.disabled ? "cursor: pointer;" : "cursor: not-allowed;";
    }

    get showCameraButton(): boolean {
        if( !this.$store ) return false;
        return this.$store.getters["isAndroid"];
    }

    get fileNumberValidation(): { label: string, class: string } | undefined {
        if( !this.fileNumberLimit ) {
            return undefined;
        } else {
            const diff = this.fileNumberLimit - this.files.length;
            if( diff === this.fileNumberLimit ) {
                // 添付されていない
                return { label: `${this.fileNumberLimit}個まで添付できます`, class: "" };
            } else if( 0 < diff && diff < this.fileNumberLimit ){
                // 上限数未満
                return { label: `あと${diff}個添付できます`, class: "" };
            } else if( diff === 0 ) {
                // 上限数添付
                return { label: `${this.files.length}/${this.fileNumberLimit}個添付しています`, class: "warning" };
            } else {
                return undefined;
            }
        }
    }

    /* フォームのdisabled */
    get disabled(): boolean {
        if( !this.allow_attachment ) {
            return true;
        } else if( this.fileNumberValidation && this.fileNumberValidation.class === "warning" ) {
            return true;
        } else {
            return false;
        }
    }

    // 拡張子設定
    accept(originAcceptList?: string[]): string {
        return originAcceptList ? getAccept(this.allow_attachment_type, originAcceptList) : getAccept(this.allow_attachment_type);
    }

    // <input>にcaptureが指定されている時のaccept設定
    captureAccept(): string {
        return getCaptureAccept(this.allow_attachment_type)
    }

    /* ファイル一覧に追加 */
    addFile(e: { target: HTMLInputElement } ): void {
        const files = e.target.files;
        if (!files) return;
        this.pushFiles(files);
    }

    pushFiles(files: FileList): void {
        // 同名排除
        const noDuplicated = Array.from(files).filter( input => !this.files.find( file => this.getFileName(file) == input.name ) );

        // 単一選択時は複数選択されたとしても先頭のみを使う
        if( this.multiple ) {
            const tmpFiles: (File | Attachment)[] = [];
            const fileArray: File[] = [];
            // エラー判定
            const result = this.judgeFilesProcess(noDuplicated);

            if( result ) {
                noDuplicated.forEach( f => {
                    tmpFiles.push( f );
                    fileArray.push( f );
                })

                tmpFiles.forEach( f => this.files.push(f) )
                this.$store.dispatch("files/pushPdfThumbStore", fileArray);
            }
        } else if( 1 <= files.length ) {
            // エラー判定
            const result = this.judgeFilesProcess(noDuplicated);
            if( result ) {
                if( noDuplicated.length ) {
                    this.files.splice( 0 ); // クリア
                    this.files.push( noDuplicated[0] );
                }
            }
        }
        this.$emit("onUpdateThumb", this.files);
    }

    // 添付ファイルの削除
    deleteFile(index: number): void {
        const file = this.files[index];
        if( AttachmentFactory.isAttachment( file ) ) {
            this.deletedFiles.push(file);
        }
        this.files.splice(index,1);
        this.$emit("onUpdateThumb", this.files);
    }

    // ファイル名の取得
    getFileName(file: (File | Attachment)): string {
        if( AttachmentFactory.isAttachment( file ) ) {
            let fileStr = file.url;
            if(fileStr.slice(-1) == '/' ) {
                fileStr = fileStr.slice(0, -1);
            }
            const splitUrl = fileStr.split(path.sep);
            return S3AccessUtility.decodeName(splitUrl[splitUrl.length-1]);
        } else {
            return file.name;
        }
    }

    // 追加されたファイル判定処理
    judgeFilesProcess(files: File[]): boolean {
        const originDenyList = this.contentType === "TOPIC" ? this.denyMimeType : [];

        // 1. 掲示板が対応していないファイルを選択した場合
        const typeErrorFiles = files.filter(file => !judgeType( file.type, originDenyList )); // 添付OKの場合で判定
        if( typeErrorFiles.length ) {
            const fileNameList = typeErrorFiles.map(file => { return file.name; });
            this.differentFileType( fileNameList );
            return false;
        }

        // 2. directの添付ファイル設定 (allow_attachment_type)に沿わない場合
        const denyMimeType = getDenyMimeList(this.allow_attachment_type, originDenyList);
        const notAllowedFiles = files.filter(file => !judgeType( file.type, denyMimeType )); // direct組織設定に沿って判定
        if( notAllowedFiles.length ) {
            const fileNameList = notAllowedFiles.map(file => { return file.name; });
            this.noAllowedFileType( fileNameList );
            return false;
        }

        // 3. 添付可能ファイル数を超えてた場合
        if( this.multiple && this.fileNumberLimit && ( files.length > this.fileNumberLimit - this.files.length ) ) {
            // *複数添付可能な時のみ判定する
            this.overFileNumber();
            return false;
        }

        // 4. ファイルサイズ上限を超えた場合
        const sizeOverFiles = files.filter( file => file.size > this.fileSizeLimit );
        if( sizeOverFiles.length ) {
            const fileNameList = sizeOverFiles.map(file => { return file.name; });
            this.overFileSize( fileNameList );
            return false;
        }

        return true;
    }

    /* ファイルエラーイベント */

    // 掲示板に対応しているか
    differentFileType(fileNameList: string[]): void {
        const errorMsg = `下記の添付ファイルは対応しておりません。\n\n${fileNameList.join('\n')}`
        this.$root.$emit('show-error-modal', { msg: errorMsg, afterProcess: ()=>{return} })
    }

    // direct組織での禁止
    noAllowedFileType(fileNameList: string[]): void {
        const errorMsg = `下記のファイルは組織設定で許可しているファイル形式と異なるため添付できません。\n\n${fileNameList.join('\n')}`
        this.$root.$emit('show-error-modal', { msg: errorMsg, afterProcess: ()=>{return} })
    }

    // ファイル数が上限オーバー
    overFileNumber(): void {
        if( !this.fileNumberLimit  ) { return; }
        this.$root.$emit('show-error-modal', { msg: `添付できるファイルは${this.fileNumberLimit}個までです。`, afterProcess: ()=>{return} })
    }

    // ファイルサイズ上限オーバー
    overFileSize(fileNameList: string[]): void {
        const errorMsg = `下記のファイルは${this.fileSizeLimitUnit}を超えているため添付できません。\n\n${fileNameList.join('\n')}`
        this.$root.$emit('show-error-modal', { msg: errorMsg, afterProcess: ()=>{return} })
    }


    // 同じファイルを連続で送るためのリセット
    resetFile(): void {
        this.target = null;
    }

    // fileListViewerにセットされたデータリセット
    resetFileList(): void {
        this.files = [];
        this.target = null;
    }

    // D&D処理
    dropFiles(files: FileList): void {
        if( !this.dropLock ) {
            this.dropLock = true;
            this.pushFiles(files);
            setTimeout(() => this.dropLock = false, 500); // 500ms後にD&D受付再開
        }
    }

    // Lifecycle
    created(): void {
        if( this.photos.length ) {
            this.files = this.photos.slice();
        }
    }
}
